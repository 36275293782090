import React, { useEffect, useRef, useState } from "react";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setValue } from "../../utility";
import { fetchProductAllCategories } from "../../redux/Actions/productCategoriesAction";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import { deleteCartCount } from "../../redux/Actions/cartCountAction";
import { setUserProductCategories } from "./../../redux/Actions/productCategoriesAction";

function CartSummary(close:any) {
  const [cartInformation, setCartInformation] = useState<any>([]);

  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const popupRef = useRef<any>(null);
  const state: any = useSelector((state) => state);

  // const selectedCategories: any = useSelector<any>(
  //   (state) => state.userSelectedCategories
  // );
  const settings: any = useSelector<any>(
    (state) => state.settings
  );

  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );

  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );
  
  const minimum_order_amount: any = useSelector<any>(
    (state) => state.getShopByPinCode?.data?.minimum_order_amount
  )

  const { cartCount }: any = useSelector<any>((state) => state);

  useEffect(() => {
  //  dispatch(fetchProductAllCategories())
  // 
  // 

  setCartInformation([]);
  
    if(selectedCategories && selectedCategories?.length){
      const cartInformationData = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
  
      if(cartInformationData.length){
        setValue("cartInformationData",cartInformationData);
        
      }
      setCartInformation(cartInformationData);
      
  
      const selectedMainCategory = selectedCategories?.category?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
  
      let finalTotalPrice = 0;
      let itemsCount = 0;
  
      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) * parseInt(subcategory?.total_price);
        });
      });
  
        // dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
    }

    // dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
  }, [selectedCategories]);

  const handleProceedTopay = async () => {
    let cartTotalPrice = cartInformation?.map((item: any) => {
      return item?.sub_categories
        ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
        ?.map((subCategory: any) => parseInt(subCategory?.quantity) *
                                        parseFloat(subCategory?.total_price)).reduce((prev: any, curr: any)=> prev+curr,0);
      })
      let cartItemsCount = cartInformation?.map((item: any) => {
        return item?.sub_categories
          ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
        })
      let cartFinalPrice = cartTotalPrice[0]
      let cartTotalItemCount = cartItemsCount[0].length
      await dispatch(fetchSplitPriceDetails({ cartFinalPrice, cartTotalItemCount }));

    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    const postalCode = JSON.parse(localStorage.getItem('postalCode')!);
    const shopId = localStorage.getItem('shop_id')
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: shopId,
      postal_code: postalCode,
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)
        
        
        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) * parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
        };
      });
    });

    payload = { ...payload, total_price: finalTotalPrice.toString() };
    localStorage.setItem("order_type",payload.order_type)
    localStorage.setItem("shop_id",payload.shop_id)
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
    
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    if (userinfo) {
      navigate("/checkout");
    } else {
      navigate("/guestLogin");
    }
  };
  var cartoverlay = document.getElementById('cd-shadow-layer');
  var cdcart = document.getElementById('cd-cart');

  const cartboxClose = () => {
    cdcart?.classList.remove('speed-in');
    cartoverlay?.classList.remove('is-visible');
  }

  const delCartItem = (selectedSubCategoryId: any, selectedMainCategoryId: any) => {
    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) =>
        item.main_category_id === (selectedMainCategoryId).toString()
    );



    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );
    
    
    
    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    updatedQuantity = updatedQuantity * 0;
      dispatch(deleteCartCount(0));


    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };


    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );
    
    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === (selectedMainCategoryId).toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));

  };
  // useEffect(() => {
  //   function handleClickOutside(event:any) {
  //     if (!popupRef.current.contains(event.target))  {
  //       close();
        
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

    
  return (
      <div id="cd-cart" ref={popupRef}>
        <div className="cart-header">
          <h4 className="text-uppercase"><i className="fas fa-cart-plus"></i>Cart Summary</h4>
          <i className="fas fa-times" onClick={cartboxClose}></i>
        </div>
        <div id="laundry-cart-body">
          <div id="cart-items-container d-block">
            {/* {cartInformation?.map((item: any) => {
                      <>
                        ( <ul className="cd-cart-items mb-3 pb-0">
                            <li className="main-title text-center">
                                <span className="text-capitalize">{item?.main_category_name}</span>
                            </li>
                            {item?.sub_categories
                                    ?.filter(
                                      (subItem: any) =>
                                        parseInt(subItem.quantity) > 0
                                    )
                                    ?.map((subCategory: any) => {
                            (<li className="d-flex justify-content-between align-items-center text-start cart-list-style" id="cart_list_1_1_1">
                                <span className="cd-qty">{ subCategory?.quantity}x</span>  {subCategory?.sub_category_name}{" "}
                                <span className="ml-3 cd-price"> {parseInt(
                                                    subCategory?.quantity
                                                  ) *
                                                    parseInt(
                                                      subCategory?.total_price
                                                    )}</span>
                                <a href="#" className="d-block cd-item-remove cd-img-replace">X</a>
                            </li>);
                               })}
                        </ul> );
                        </>
                      })} */}
            {cartInformation?.map((item: any) => {
              return (
                <>
                  <div className="option-price">
                                       
                    <div className="car-sum-cat-title">
                      {item?.main_category_name}
                    </div>

                    {item?.sub_categories
                      ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
                      ?.map((subCategory: any) => {
                        return (
                          <div className="d-flex justify-content-between align-items-start cart-list-style">
                            <div className="cartsum-left">
                              <span className="cartsum-itemqty theme-clr-primory me-2">{subCategory?.quantity}X</span>
                              <span className="cartsum-item">{subCategory?.sub_category_name}{" "}</span>
                                <span className="option-total d-none">
                                {cartCount}
                                </span>
                            </div>
                            <div className="cartsum-right">
                              <span className="cartsum-price theme-clr-primory">{settings?.WebmasterSettings?.currency}{parseInt(subCategory?.quantity) *
                                      parseFloat(subCategory?.total_price)}</span>
                              <button className="cat-item-close-btn ms-2" 
                              onClick={() =>
                                delCartItem(
                                  subCategory.sub_category_id,
                                  item.main_category_id
                                )
                              }
                              >X</button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            })}
            {/* <div className="cd-cart-total">
                            <p>Total <span>{settings?.WebmasterSettings?.currency}<span id="cart_total_amt">70.00</span></span></p>
                        </div> */}
            {(cartInformation.length > 0 || Object.keys(cartInformation).length > 0) && (
              <div>
                <div className="payable-price payable-price-cartsum">
                  <span className="option-title">Total</span>
                  <span className="option-total total-amt">
                  {settings?.WebmasterSettings?.currency}{cartInformation?.map((item: any) => {
                      return item?.sub_categories
                        ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
                        ?.map((subCategory: any) => parseInt(subCategory?.quantity) *
                                                        parseFloat(subCategory?.total_price)).reduce((prev: any, curr: any)=> prev+curr,0);
                      })}
                  </span>
                </div>
                <button
                  className="checkout-btn btn text-uppercase hover-btn"
                  onClick={handleProceedTopay}>
                  Proceed to pay securely
                </button>
              </div>
            )}
            
            <div className="text-center mt-3 text-success">
              <small>
                <b>Note:</b> Minimum order amount is {settings?.WebmasterSettings?.currency}{minimum_order_amount || ''}
              </small>
            </div>
            <p className="cd-go-to-cart">
              <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/productLists')}}>
              <i className="fas fa-long-arrow-alt-left"></i> Go to cart page
              </a>
            </p>
          </div>
          <div id="cart-empty-container d-none">
            <p
              className="text-center pb-5">
              Your cart is empty. Please add something.
            </p>
            <p className="cd-go-to-cart">
              <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/productLists')}}>
              <i className="fas fa-long-arrow-alt-left"></i> Go to cart page
              </a>
            </p>
          </div>
        </div>
      </div>    
  );
}

export default CartSummary;
