import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="Ease to Made Laundry" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h2 className="text-start">Ease to Made Laundry</h2>
</div>
<div className="">
<p>We provide laundry pick up and delivery service so that you're never standing in a laundrette for long. We'll collect, wash, and dry your clothes in a professional manner and deliver them back to you with impeccably folded items.</p>
<p>You can select an item to wash, dry, and iron. We offer convenient laundry service packages that you can choose from such as "Wash, Tumble Dry & Fold" or "Wash & Iron" depending on your needs.</p>
<p> Book in a few clicks and you can get your clothes delivered and picked up quickly, starting your week fresh!</p>
</div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;