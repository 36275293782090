import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { readNotification } from '../../../redux/Actions';

function AllNotifications() {

  const [notificationList, setNotificationList] = useState([])
  const [noNotification, setnoNotification] = useState(false)
  const [markReadNotification, setReadNotification] = useState([] as Array<any>)
  const dispatch = useDispatch<any>();
  const state: any = useSelector(state => state);

  const token = localStorage.getItem("token");

  const base_url = process.env.REACT_APP_BACKEND_URL;
  
  useEffect(() => {
    axios({
      method: "get",
      url: `${base_url}/api/user_notification_list`,
      headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
    }).then((response) => {
      if (response.status != 401) {
        setNotificationList(response.data.data);
        if(response.data.Response.response_message === "notifications not available"){
          setnoNotification(true)
        }
      }
    }).catch(function (error) {
      
    })

  }, [state])

  const markAsRead = (item: any) => {
    if (item.read != 1) {
      
      dispatch(readNotification(item.id));
      
      
      var newArray = markReadNotification.concat(item.id)
      
      newArray = newArray.filter(function (elem, pos) {
        return newArray.indexOf(elem) == pos;
      })
      
      setReadNotification(newArray)
      
    }
  };


  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Notifications</h2>
                <ul>
                  <li>
                    <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                  </li>
                  <li>
                    <span>Notifications</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-mobnotification">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {notificationList.map((item: any, index: number) => (
                <div className="alert alert-dismissible nf-dismissible" onClick={() => markAsRead(item)} key={item.id}>
                  <div className="notification-content">
                    <div className="nf-content">
                      <p className={(markReadNotification.includes(item.id) || (item.read == 1)) ? "fw-normal" : "fw-bold"}>{index+1}. {item.title}</p>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.body}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {noNotification &&
            <div>
              <p>No Notification Found</p>
            </div>
            }
          </div>
        </div>
      </section>
    </div>


  )
}

export default AllNotifications;